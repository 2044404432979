import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

// Todo: Add page specific JS here e.g:
import * as lightbox from '@elements/lightbox';
lightbox.init();


import * as accordion from './scripts/accordion.js';
accordion.init();

//bootstrap imports
import Collapse from 'bootstrap/js/dist/collapse';

import * as tabs from './scripts/tabs';
tabs.init();

// Image Row
import * as imageRow from './scripts/image-row';
imageRow.init();

// Bildergalerie
import * as imageGallerySlider from './scripts/image-gallery-slider';
imageGallerySlider.init();

// Content-teaser-slider
import * as genericSlider from './scripts/generic-slider';
genericSlider.init();

// Photo Grid
import * as photoGrid from './scripts/photo-grid';
photoGrid.init();

// Referenc Slider
import * as teaserSliderLg from './scripts/teaser-slider-lg';
teaserSliderLg.init();

import * as logoSlider from './scripts/logo-slider';
logoSlider.init();

import * as factArea from './scripts/fact-area';
factArea.init();

import * as ajaxModal from './scripts/ajax-modal';
ajaxModal.init();
